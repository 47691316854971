import { useCallback, useRef, useEffect } from 'react';
import Router from 'next/router';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { pageView } from '../state/app';

const matches = (testPath: string) => {
    const pattern = new RegExp(`^\/${testPath}[^a-zA-Z0-9]?.*$`, 'gi');
    return pattern.test(Router.pathname);
};

const equals = (testPath: string) => {
    return Router.pathname === testPath;
};

const usePageView = () => {
    const dispatch = useAppDispatch();
    const routePending = useAppSelector((state) => state.app.routePending);

    const lastTrackedPage = useRef(null);

    const trackPageView = useCallback(() => {
        // Only track pageviews once any auth redirects have completed
        if (routePending) {
            return;
        }

        // Prevent repeat pageviews
        if (lastTrackedPage.current === Router.pathname) {
            return;
        }

        const query = Router.query;

        lastTrackedPage.current = Router.pathname;

        if (equals('/') || equals('/mobile'))
            return dispatch(
                pageView({
                    title: 'Landing',
                })
            );

        if (matches('home'))
            return dispatch(
                pageView({
                    title: 'Home',
                })
            );

        if (matches('login'))
            return dispatch(
                pageView({
                    title: 'Login',
                })
            );

        if (matches('invite'))
            return dispatch(
                pageView({
                    title: 'Invite',
                })
            );

        if (matches('download'))
            return dispatch(
                pageView({
                    title: 'Download',
                })
            );

        if (equals('/room/[slug]/chat'))
            return dispatch(
                pageView({
                    title: 'Popout Chat',
                    slug: query.slug,
                })
            );

        if (matches('room'))
            return dispatch(
                pageView({
                    title: 'Room',
                    slug: query.slug,
                })
            );

        return dispatch(pageView({}));
    }, [routePending]);

    useEffect(() => {
        if (Router.isReady) {
            trackPageView();
        }

        Router.events.on('routeChangeComplete', trackPageView);

        return () => {
            Router.events.off('routeChangeComplete', trackPageView);
        };
    }, [dispatch, trackPageView]);

    return trackPageView;
};

export default usePageView;
